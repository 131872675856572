
import React from "react"
import {api_freedom} from '../services/apis'
import jwt_decode from 'jwt-decode'
import moment from 'moment'

class Contrato extends React.Component {

  constructor(props) {
    super(props)
    if(!localStorage.getItem('autenticado_freedom')){
        props.history.push('/')
    } 

    this.state = {
        dados_login: jwt_decode(localStorage.getItem('autenticado_freedom')),
        contrato: {}
    }

  }

  componentDidMount(){
    this.contrato()
  }

  contrato = async () => {
    try{
        let contrato = await api_freedom.get(`/contrato/detalhe/${this.props.match.params.id}/${jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id}`, {headers: {"authorization": localStorage.getItem('autenticado_freedom')}})
        console.log(contrato.data.data[0])
        this.setState({contrato: contrato.data.data[0]})
    }
    catch(error){
        console.log(error.message)
    }


  }


  render() {
    return (
      <>
        <div>
            <h2 style={{marginTop: '15px'}}>Dados do contrato</h2>
            <strong>Contrato</strong>: {this.state.contrato.id}<br/>
            <strong>Início</strong>: {moment(this.state.contrato.data_inicio).format('DD/MM/YYYY')}<br/>
            <strong>Término</strong>: {moment(this.state.contrato.data_fim).format('DD/MM/YYYY')}<br/>
            <strong>Valor de aluguel</strong>: {this.state.contrato.valor_aluguel && this.state.contrato.valor_aluguel.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}<br/>
            {/* <strong>Valor das demais parcelas</strong>: {this.state.contrato.valor_demais_parcela && this.state.contrato.valor_demais_parcela.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}<br/>
            <strong>Valor da multa do boleto</strong>: {this.state.contrato.valor_multa_boleto && this.state.contrato.valor_multa_boleto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}<br/>
            <strong>Valor TED</strong>: {this.state.contrato.valor_ted && this.state.contrato.valor_ted.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}<br/>
            <strong>Valor da emissão do boleto</strong>: {this.state.contrato.valor_emissao_boleto && this.state.contrato.valor_emissao_boleto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}<br/> */}
            <strong>Tipo de locação</strong>: {this.state.contrato.tipo_locacao}<br/>
            {/* <strong>Tipo da garantia</strong>: {this.state.contrato.tipo_garantia}<br/> */}
            {/* <strong>Renovação automática do contrato</strong>: {this.state.contrato.renovacao_automatica}<br/>
            <strong>Porcentagem da taxa de administração</strong>: {this.state.contrato.perc_taxa_administracao}%<br/>
            <strong>Porcentagem da primeira parcela</strong>: {this.state.contrato.perc_primeira_parcela}%<br/>
            <strong>Porcentagem do juros por mês</strong>: {this.state.contrato.perc_juros_mes}%<br/>
            <strong>Porcentagem dos honorários</strong>: {this.state.contrato.perc_honorarios_adv}%<br/>
            <strong>Porcentagem das demais parcelas</strong>: {this.state.contrato.perc_demais_parcela}%<br/>
            <strong>Seguradora</strong>: {this.state.contrato.nome_seguradora}<br/>
            <strong>Índice cadastral do IPTU</strong>: {this.state.contrato.iptu_indice_cadastral}<br/>
            <strong>Índice para reajuste</strong>: {this.state.contrato.indice_reajuste}<br/>
            <strong>Incêndio apólice</strong>: {this.state.contrato.incendio_apolice}<br/>
            <strong>Vencimento do seguro contra incêndio</strong>: {moment(this.state.contrato.vencimento_seguro_incendio).format('DD/MM/YYYY')}<br/>
            <strong>Gás de ligação</strong>: {this.state.contrato.gas_ligacao}<br/>
            <strong>Energia de ligação</strong>: {this.state.contrato.energia_ligacao}<br/>
            <strong>Água de ligação</strong>: {this.state.contrato.agua_ligacao}<br/>
            <strong>Dia do vencimento do aluguel</strong>: {this.state.contrato.dia_vencimento_aluguel}<br/>
            <strong>Data do último reajuste</strong>: {this.state.contrato.data_ultimo_reajuste}<br/>
            <strong>Apólice</strong>: {this.state.contrato.apolice}<br/> */}
            <strong>Número de parcelas</strong>: {this.state.contrato.financeiro && this.state.contrato.financeiro.filter((f) => {
                return f.nome_servico === 'Locação'
            }).length}
            
            <hr/>
            <h2 style={{marginTop: '15px'}}>Dados do imóvel</h2>
            <strong>CEP</strong>: {this.state.contrato.imovel && this.state.contrato.imovel[0].cep}<br/>
            <strong>Logradouro</strong>: {this.state.contrato.imovel && this.state.contrato.imovel[0].endereco}<br/>
            <strong>Número</strong>: {this.state.contrato.imovel && this.state.contrato.imovel[0].numero}<br/>
            <strong>Complemento</strong>: {this.state.contrato.imovel && this.state.contrato.imovel[0].complemento}<br/>
            <strong>Bairro</strong>: {this.state.contrato.imovel && this.state.contrato.imovel[0].bairro}<br/>
            <strong>Cidade</strong>: {this.state.contrato.imovel && this.state.contrato.imovel[0].cidade}<br/>
            <strong>Estado</strong>: {this.state.contrato.imovel && this.state.contrato.imovel[0].estado}<br/>
            {/* <strong>Metragem</strong>: {this.state.contrato.imovel && this.state.contrato.imovel[0].metragem}<br/>
            <strong>Nome do administrador ou sindico</strong>: {this.state.contrato.imovel && this.state.contrato.imovel[0].nome_adm_sindico}<br/>
            <strong>Email do administrador ou sindico</strong>: {this.state.contrato.imovel && this.state.contrato.imovel[0].email_adm_sindico}<br/>
            <strong>Telefone do administrador ou sindico</strong>: {this.state.contrato.imovel && this.state.contrato.imovel[0].telefone_adm_sindico}<br/> */}

            <hr/>
            <h2 style={{marginTop: '15px'}}>Dados das pessoas</h2>
            {this.state.contrato.pessoas && this.state.contrato.pessoas.map((p) => {
                return (
                    <div >
                        <strong>Tipo</strong>: {p.tipo}<br/>
                        <strong>Nome/Razão Social</strong>: {p.nome}<br/>
                        <strong>CPF/CNPJ</strong>: {p.cpf_cnpj}<br/>
                        {/* <strong>RG</strong>: {p.rg_ie}<br/> */}
                        <strong>Email</strong>: {p.email}<br/>
                        <strong>Telefone(s)</strong>: {p.telefone}<br/>
                        <strong>Celular(es)</strong>: {p.celular}<br/>
                        {/* <strong>Porcentagem do imóvel</strong>: {p.percentagem}%<br/>
                        <strong>Banco</strong>: {p.banco}<br/>
                        <strong>Agência</strong>: {p.agencia}<br/>
                        <strong>Conta</strong>: {p.conta}<br/>
                        <strong>Repasse garantido ao locador</strong>: {p.repasse_garantido_locador}<br/>
                        <strong>Dias repasse locador</strong>: {p.dias_repasse_locador}<br/>
                        <strong>CEP</strong>: {p.cep}<br/>
                        <strong>Logradouro</strong>: {p.endereco}<br/>
                        <strong>Número</strong>: {p.numero}<br/>
                        <strong>Complemento</strong>: {p.complemento}<br/>
                        <strong>Bairro</strong>: {p.bairro}<br/>
                        <strong>Cidade</strong>: {p.cidade}<br/>
                        <strong>UF</strong>: {p.uf}<br/>
                        <strong>Porcentagem recibo</strong>: {p.perc_recibo}<br/>
                        <strong>Nome/Razão Social do beneficiário</strong>: {p.nome_beneficiario}<br/>
                        <strong>CPF/CNPJ do beneficiário</strong>: {p.cpf_cnpj_beneficiario}<br/> */}
                        <hr/>
                    </div>
                )
            })}

            {/* <h2 style={{marginTop: '15px'}}>Dados financeiros</h2>
            {this.state.contrato.financeiro && this.state.contrato.financeiro.map((f) => {
                return (
                    <div >
                        <strong>Data de vencimento</strong>: {moment(f.data_vencimento).format('DD/MM/YYYY')}<br/>
                        <strong>Serviço</strong>: {f.nome_servico}<br/>
                        <strong>Tipo pagamento</strong>: {f.tipo_pagamento}<br/>
                        <strong>Valor</strong>: {f.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}<br/>
                        <strong>Identificador da pessoa</strong>: {f.pessoa_id}<br/>
                        <hr/>
                    </div>
                )
            })} */}
        </div>
      </>
    );
  }
}

export default Contrato;

import React from 'react'
import { api_freedom } from '../services/apis'
import jwt_decode from 'jwt-decode'


class ContratosReajustesPage extends React.Component {
    constructor(props) {
        super(props)

        if (!localStorage.getItem('autenticado_freedom')) {
            props.history.push('/')
        }

        let d = new Date()


        this.state = {
            dados_login: jwt_decode(localStorage.getItem('autenticado_freedom')),
            contratos: [],
            indices: [],
            mesAjuste: `${d.getFullYear()}-${this.twoZeros(d.getMonth() + 1)}`
        }

        this.getContratos()
    }

    twoZeros = (d) => d.toString().length == 1 ? `0${d}` : d

    getContratos = async () => {
        let { data } = await api_freedom.get('/indice/valores/all/', { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })
        document.querySelector('.loader__back').classList.add("active")

        let re = await api_freedom.post("/contrato/mesajuste/", {
            mes: Number(this.state.mesAjuste.split("-")[1]),
            imob: this.state.dados_login.imobiliaria_id
        }, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })

        if (re.data.data.indexOf('TOKEN') === -1 && data.data.indexOf('TOKEN') === -1) {
            let contratos = re.data.data

            for (const i of contratos) {
                i.renovar = false
                i.index_id = -1
                i.index_qt = -1
                i.index_per = 0

                if (!i.indice_reajuste) {
                    continue
                }
                let ind = data.data.find(e => e.nome.toLowerCase() == i.indice_reajuste.toLowerCase())


                if (ind) {
                    i['index_id'] = ind.id
                    i['index_qt'] = ind.valores.length
                    i.qt_media = ind.qt_media

                    if (ind.valores.length > 0) {
                        i.index_per = ind.valores[0].porcentagem
                    }
                }
            }

            document.querySelector('.loader__back').classList.remove("active")

            this.setState({
                indices: data.data,
                contratos: contratos
            })
        }
    }

    changeIndex = (id, contrato_index) => {

        let ind = this.state.indices.find(e => e.id == id)
        console.log(ind);
        let c = this.state.contratos

        if (c[contrato_index]?.indice && c[contrato_index]?.indice !== ind?.nome) {
            alert("Mude o índice diretamente no contrato!");
            return;
        }

        c[contrato_index].index_id = -1
        c[contrato_index].index_qt = -1
        c[contrato_index].index_per = 0
        c[contrato_index].index_per = 0

        if (ind) {

            c[contrato_index]['index_id'] = ind.id
            c[contrato_index]['index_qt'] = ind.valores.length
            c[contrato_index].qt_media = ind.qt_media

            if (ind.valores.length > 0) {
                c[contrato_index].index_per = ind.valores[0].porcentagem
            } else {
                alert("ALERTA! Este indíce não contêm um mês para realizar o reajuste.")
            }
        }

        this.setState({
            contratos: c
        })
    }

    changeCheck = (index) => {
        let c = this.state.contratos

        c[index].renovar = !c[index].renovar

        this.setState({
            contratos: c
        })
    }

    applyRajuste = async () => {
        let d = new Date()

        let checker = false
        for (const i of this.state.contratos.filter(y => y.renovar)) {
            if (i.index_id == -1) {
                alert("Selecione um indíce para realizar o ajuste no contrato " + i.id)
                continue
            }

            if (i.index_per == 0) {
                alert("Este indíce não contêm " + i.qt_media + " meses para realizar o reajuste")
                continue
            }

            await api_freedom.post("/contrato/ajustecontrato/", {
                id: i.id,
                imob: i.imobiliaria_id,
                per: i.index_per,
                valor: i.valor_aluguel,
                mes: `${d.getFullYear()}-${this.twoZeros(d.getMonth() + 1)}`
            }, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })
            checker = true
        }

        if (checker)
            alert("SUCESSO! Reajuste aplicado!")
        else
            alert("ERRO! Reajuste não fora aplicado!")

        this.getContratos()
    }

    changeMonth = (d) => {
        try {
            let m = d.split("-")[1]
            if (m.length == 2) {
                console.log(m);
                this.getContratos();
            }
        } catch (error) { }
    }

    render() {
        return (
            <>
                <div className="loader__back">
                    <div className="loader__rolling">
                        <div className="loader"></div>
                    </div>
                </div>
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <p className="relatorio-title">Contratos a reajustar</p>
                            <br />
                            <div className="grupo__campo__form grupo__campo--input--select">
                                <input type="month" value={this.state.mesAjuste} onChange={a => { this.setState({ mesAjuste: a.target.value }); this.changeMonth(a.target.value) }} />
                            </div>
                        </div>
                        <div>
                            <button className='tohidden btn__primary button' onClick={() => this.applyRajuste()}>Aplicar reajuste</button>
                        </div>
                    </div>
                    <div className='table mt-4'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>
                                        <div class="thead__item">
                                            <label>Reajustar?</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Contrato</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Locador</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Locatário</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Aluguel</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Indíce de reajuste</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label>Aluguel após reajuste</label>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.contratos.map((e, index) => {
                                        let indice = this.state.indices.find(x => x.nome == e.indice);
                                        if (indice?.id) {
                                            indice = indice.id;
                                            e.index_id = indice;
                                            e.index_per = this.state.indices.find(x => x.id == indice).valores[0].porcentagem;
                                        } else {
                                            indice = undefined;
                                        }
                                        return (
                                            <tr>
                                                <td className="tbody__text">
                                                    <label class="mb-0 checkbox-label">
                                                        <input type="checkbox" checked={e.renovar} onChange={a => this.changeCheck(index)} />
                                                        <span class="checkbox-custom rectangular"></span>
                                                    </label>
                                                </td>
                                                <td className="tbody__text">{e.id}</td>
                                                <td className="tbody__text">
                                                    {e.pessoas.filter(y => y.tipo == "Locador").map(locador => (
                                                        <div key={locador.cpf_cnpj}><label>{`${locador.nome} | ${locador.cpf_cnpj}`}</label><br /></div>
                                                    ))}
                                                </td>
                                                <td className="tbody__text">
                                                    {e.pessoas.filter(y => y.tipo == "Locatário").map(locador => (
                                                        <div key={locador.cpf_cnpj}><label>{`${locador.nome} | ${locador.cpf_cnpj}`}</label><br /></div>
                                                    ))}
                                                </td>
                                                <td className="tbody__text">{e.valor_aluguel.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                <td className="tbody__text">
                                                    <select value={e.index_id} onChange={a => this.changeIndex(a.target.value, index)} readOnly={indice ? true : false}>
                                                        <option value="">Selecione um indíce</option>
                                                        {
                                                            this.state.indices.map(x => (
                                                                <option value={x.id}>{x.nome}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </td>
                                                <td className="tbody__text">
                                                    {
                                                        e.index_per == 0 ?
                                                            '---' :
                                                            (Math.round((e.valor_aluguel + (e.valor_aluguel * e.index_per) / 100) * 100) / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>

            </>
        )
    }
}

export default ContratosReajustesPage
import { api_freedom, api_freedom_link } from '../services/apis'
import jwt_decode from 'jwt-decode'
import jwt from 'jsonwebtoken'
import moment from 'moment'
import React from "react"
import readxlsx from "read-excel-file"

class Contratos extends React.Component {

    constructor(props) {
        super(props)
        if (!localStorage.getItem('autenticado_freedom')) {
            props.history.push('/')
        }

        this.state = {
            dados_login: jwt_decode(localStorage.getItem('autenticado_freedom')),
            contratos: [],
            contratosAtivados: [],
            mensagem: 'Carregando ....',
            total_contratos: 0,
            buscar_contrato: '',
            buscar_imovel: '',
            buscar_proprietarios: '',
            buscar_locatario: '',
            buscar_inicio: '',
            buscar_termino: '',
            buscar_aluguel: '',
            pagina: 1,
            quantidade_paginas: 1,
            pagina_anterior: 0,
            por_pagina: 10,
            proxima_pagina: 2,
            modal_titulo: '',
            modal_corpo: '',
            modal_botoes: '',

            dataInicio: '',
            dataFim: '',
            dataRescisao: '',
            valorAluguel: '',
            contrato_id: '',
            contrato_endereco: '',
            dia_vencimento_aluguel: '',
            aviso: 'Preencha todos os campos',
            displayAviso: false,
            fct: '',
            fim: '',
            fpt: '',
            fin: '',
            ftm: '',
            frc: '',
            fva: '',
            load: false,
            aberto_fechado: '',
            ativos: '1',
            inPrint: false,
            modelos: [],
            modeloSelected: '',

            planilha: '',
            planilhaData: [],
            planExistsContrato: [],
            send_docs: false,
            mail_docs: false,
            docs_contrato: [],
            contratoSelected: {},
            nome_doc: '',
            file_doc: null,
            box_mail: false,
            file_doc_selected: {},
            box_mail_other: '',
            flag_rescisao: false,

            selectedOption: 'Ações',
        }


    }

    async componentDidMount() {
        this.getModelos();
        await this.contratos();
    }

    async componentDidUpdate(prevProps, prevState) {

        if (prevState.pagina !== this.state.pagina || prevState.ativos !== this.state.ativos || prevState.por_pagina !== this.state.por_pagina || prevState.flag_rescisao !== this.state.flag_rescisao) {
            await this.contratos();
        }

    }

    getModelos = async () => {
        let re = await api_freedom.get("/modelo/list", { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })

        if (re.data.data.indexOf('TOKEN') === -1) {
            this.setState({
                modelos: re.data.data,
                modeloSelected: re.data.data[0] ? re.data.data[0].id : ""
            })
        }
    }

    contratos = async () => {
        try {
            this.setState({ load: true })
            // let contratos = await api_freedom.get(`/contrato/idimobiliaria/${this.state.dados_login.imobiliaria_id}/1`, {headers: {"authorization": localStorage.getItem('autenticado_freedom')}})
            let contratos = await api_freedom.get(`/contrato/`,
                {
                    headers: { "authorization": localStorage.getItem('autenticado_freedom') },
                    params: {
                        imobiliaria_id: this.state.dados_login.imobiliaria_id,
                        pagina: this.state.pagina,
                        limite: this.state.por_pagina,
                        ativo: this.state.ativos,
                        contrato_id: this.state.buscar_contrato,
                        rua_imovel: this.state.fim,
                        proprietario: this.state.fpt,
                        locatario: this.state.flc,
                        data_inicio: this.state.fin,
                        data_rescisao: this.state.frc,
                        data_termino: this.state.ftm,
                        valor_aluguel: this.state.fva,
                    }
                })
            console.log(contratos.data);
            if (contratos.data.data.data.indexOf('TOKEN') === -1) {
                let updateVerify = false;
                let hoje = moment().format('YYYY-MM-DD')
                this.setState({
                    contratos: contratos.data.data.data,
                    contratosAtivados: this.filtroAtivo(contratos.data.data.data),
                    mensagem: 'Nenhum registro encontrado.',
                    total_contratos: contratos.data.data.total,
                    quantidade_paginas: contratos.data.data.total_paginas,
                })

                for (const contrato of contratos.data.data.data) {
                    if (contrato.data_rescisao !== null && contrato.id !== '' && contrato.imobiliaria_id !== '' && contrato.ativo !== 0) {
                        var rescisaoData = moment(contrato.data_rescisao).format('YYYY-MM-DD');
                        if (rescisaoData === hoje) {
                            const response = await this.apiContratoRescisaoVerificacao(contrato, hoje);
                            if (response) {
                                updateVerify = true;
                            }
                        }
                    }
                }

                if (updateVerify) {
                    this.setState({
                        flag_rescisao: !this.state.flag_rescisao,
                    })
                }
            }

            this.setState({ load: false })
        }
        catch (error) {
            console.log(error.message)
        }
    }

    imprimirEmModelo = (id) => {
        this.setState({
            modal_titulo: `Impressão de contrato`,
            modal_botoes: <>
                <button className='button btn__primary--outline' onClick={this.fecharModal}>Fechar</button>
                <button className='button btn__primary' onClick={a => this.props.history.push(`/imprimir/imprimirModeloContrato/${id}/${this.state.modeloSelected}`)}>Imprimir</button>
            </>,
            modal_corpo: <div>
                <div style={{ marginTop: '1%' }}>
                    <p>
                        Imprimir o contrato {id}, selecione o modelo para ser impresso
                    </p>
                    <br />
                    <div className="grupo__campo__form">
                        <select value={this.state.modeloSelected} onChange={a => this.setState({ modeloSelected: a.target.value })}>
                            {
                                this.state.modelos.map(e => (
                                    <option value={e.id}>{e.nome}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
            </div>
        })
        this.abrirModal()
    }

    listarContratos = (record, index) => {
        var class_name = "", vencido = false;
        let style = {}

        var dataRecord = moment(record.data_fim).format('YYYY-MM-DD')

        var data = moment()
        data.add(30, 'day')
        data = moment(data).format('YYYY-MM-DD')

        var hoje = moment().format("YYYY-MM-DD")

        if (record.ativo === 0) {
            style = { fontWeight: 'normal', "textDecoration": "line-through" }
        } else if (dataRecord > hoje && dataRecord < data) {
            class_name = "tr--yellow"
            vencido = true
        } else if (dataRecord < hoje) {
            class_name = "tr--red"
            vencido = true
        }

        const handleSelectOption = (event) => {
            const selectedOption = event.target.value;
            this.setState({ selectedOption });

            if (selectedOption === 'Visualizar') this.props.history.push(`/freedom/contrato/${record.id}`);
            else if (selectedOption === 'Parcelas') this.props.history.push(`/freedom/parcelas/${record.id}`);
            else if (selectedOption === 'Editar' && !this.disable()) this.props.history.push(`/freedom/alterarcontrato/${record.id}`);
            else if (selectedOption === 'Renovar Contrato' && vencido) this.prolongarContrato(record);
            else if (selectedOption === 'Rescindir Contrato' && vencido) this.anularContrato(record);
            else if (selectedOption === '5') this.imprimirEmModelo(record.id);
            else if (selectedOption === '6') this.openModalDocs(record);
            this.setState({ selectedOption: 'Ações' });
        };

        return (
            <tr className={class_name} style={style} key={index}>
                <td className="tbody__text"><p>{record.id}</p></td>
                <td className="tbody__text"><p>{`${record.endereco}, ${record.numero} ${record.complemento}, ${record.bairro} - ${record.cidade} / ${record.estado}`}</p></td>
                <td className="tbody__text">
                    {
                        record.pessoas.filter((p) => p.tipo === 'Locador').map((locador) => (
                            <div key={locador.id_relacao}><p><label style={style}>{`${locador.nome} | ${locador.cpf_cnpj}`}</label></p><br /></div>
                        ))
                    }
                </td>
                <td className="tbody__text">
                    {
                        record.pessoas.filter((p) => p.tipo === 'Locatário').map((locatario) => (
                            <div key={locatario.id_relacao}><p><label style={style}>{`${locatario.nome} | ${locatario.cpf_cnpj}`}</label></p><br /></div>
                        ))
                    }
                </td>
                <td className="tbody__text"><p>{moment(record.data_inicio).add(3, "hour").format('DD/MM/YYYY')}</p></td>
                <td className="tbody__text"><p>{moment(record.data_fim).add(3, "hour").format('DD/MM/YYYY')}</p></td>
                <td className="tbody__text"><p>{record.valor_aluguel.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p></td>
                <td className="tohidden tbody__text">
                    {/* <select onChange={(event) => {
                        if (event.target.value === 'Visualizar') this.props.history.push(`/freedom/contrato/${record.id}`)
                        if (event.target.value === 'Parcelas') this.props.history.push(`/freedom/parcelas/${record.id}`)
                        if (event.target.value === 'Editar' && !this.disable()) this.props.history.push(`/freedom/alterarcontrato/${record.id}`)
                        if (event.target.value === 'Renovar Contrato' && vencido) this.prolongarContrato(record)
                        if (event.target.value === 'Rescindir Contrato' && vencido) this.anularContrato(record)
                        if (event.target.value === '5') this.imprimirEmModelo(record.id)
                        if (event.target.value === '6') this.openModalDocs(record)
                        this.props.history.goBack();
                    }}> */}
                    <select onChange={handleSelectOption} value={this.state.selectedOption}>
                        <option>Ações</option>
                        <option>Visualizar</option>
                        <option>Parcelas</option>
                        {!this.disable() ? <option>Editar</option> : ''}
                        {vencido ? <option>Renovar Contrato</option> : ''}
                        {vencido ? <option>Rescindir Contrato</option> : ''}
                        <option value="5">Imprimir em modelo</option>
                        <option value="6">Documentos</option>
                    </select>
                </td>
            </tr>
        )
    }

    filtroAtivo = (c) => {
        if (this.state.ativos === 1) {
            return c.filter(ativos => ativos.ativo === 1)
        } else if (this.state.ativos === "") {
            return c.filter(ativos => ativos.ativo === 1)
        } else {
            return c.filter(ativos => ativos.ativo === 0)
        }
    }
    // filtroAtivo = (c, ativos = this.state.ativos) => {
    // return c.filter(y => {
    //     let data = moment()
    //     data.add(30, 'day')

    //     data = moment(data).format('YYYY-MM-DD')

    //     if (ativos == 1){
    //         return true
    //     }
    //     else if (ativos == 2) {
    //         if ((y.ativo == 1 || y.ativo == null)){
    //             return true
    //         }
    //         return false
    //     }
    //     else{
    //         if (y.ativo === 0) {
    //             return true
    //         } 

    //         return false
    //     }
    // })
    // 
    // }

    filtrar = (contratos) => {
        var filtro = contratos.filter((c) => {
            return this.filtrarContrato(c)
        })

        // console.log(this.filteer(contratos))
        var filtro = this.filteer(contratos)

        this.quantidade_paginas = Math.ceil(filtro.length / 10)

        return filtro
    }

    filtrarContrato = (contrato) => {
        return !this.state.buscar_contrato || (contrato.id && contrato.id.toString().toLowerCase().includes(this.state.buscar_contrato))
    }

    filtrarIm = (v) => {
        // Lógica para buscar e retornar com acento ou não
        var imAltered = ''
        imAltered = v.endereco.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        return !this.state.fim || (v.endereco &&
            imAltered.toString().toLowerCase().includes(this.state.fim.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")));
    }

    filtrarPt = (v) => {
        var lc = v.pessoas.filter(a => a.tipo == 'Locador')
        // Lógica para buscar e retornar com acento ou não
        var lcAltered = ''
        lcAltered = lc?.map((lc) => lc.nome.normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
        return !this.state.fpt || (lc?.map((lc) => lc.nome) &&
            lcAltered.toString().toLowerCase().includes(
                this.state.fpt.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            ));
    }

    filtrarLc = (v) => {
        var lc = v.pessoas.filter(a => a.tipo == 'Locatário')
        // Lógica para buscar e retornar com acento ou não
        var lcAltered = ''
        lcAltered = lc?.map((lc) => lc.nome.normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
        return !this.state.flc || (lc?.map((lc) => lc.nome) &&
            lcAltered.toString().toLowerCase().includes(
                this.state.flc.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            ));
    }

    filtrarIn = (v) => {
        return !this.state.fin || (moment(v.data_inicio).format('DD/MM/YYYY') &&
            moment(v.data_inicio).format('DD/MM/YYYY').toString().toLowerCase().includes(this.state.fin.toLowerCase()));
    }

    filtrarTm = (v) => {
        return !this.state.ftm || (moment(v.data_fim).format('DD/MM/YYYY') &&
            moment(v.data_fim).format('DD/MM/YYYY').toString().toLowerCase().includes(this.state.ftm.toLowerCase()));
    }

    filtrarRc = (v) => {
        return !this.state.frc || (moment(v.data_rescisao).format('DD/MM/YYYY') &&
            moment(v.data_rescisao).format('DD/MM/YYYY').toString().toLowerCase().includes(this.state.frc.toLowerCase()))
    }

    filtrarVa = (v) => {
        return !this.state.fva || (v.valor_aluguel &&
            v.valor_aluguel.toString().toLowerCase().includes(this.state.fva.toLowerCase()));
    }

    disable() {
        if (jwt_decode(localStorage.getItem('autenticado_freedom')).permissao_tipo === "Read") {
            return true
        } else {
            return false
        }
    }

    abrirModal = () => {
        var modal = document.getElementById('modal');
        modal.style.display = "flex";
    }

    fecharModal = () => {
        var modal = document.getElementById('modal');
        modal.style.display = "none";

        this.limparState()
    }

    prolongarContrato = (contrato) => {
        this.setState({
            contrato_id: contrato.id,
            dia_vencimento_aluguel: contrato.dia_vencimento_aluguel,
            aberto_fechado: contrato.aberto_fechado
        })

        var id = contrato.id
        var endereco = `${contrato.endereco}, ${contrato.numero} ${contrato.complemento}, ${contrato.bairro} - ${contrato.cidade} / ${contrato.estado}`

        this.setState({
            modal_titulo: `Aumentar tempo de contrato`,
            modal_botoes: <>
                <button className='button btn__primary--outline' onClick={this.fecharModal}>Fechar</button>
                <button className='button btn__primary' onClick={this.apiContrato}>Alterar</button>
            </>,
            modal_corpo: <div>
                <div style={{ marginTop: '1%' }}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className='grupo__campo'>
                                <label style={{ width: '100%' }}>ID do contrato: </label><br />
                                <p style={{ padding: '0px 15px' }}>{id}</p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className='grupo__campo'>
                                <label style={{ width: '100%' }}>Endereco: </label><br />
                                <p style={{ padding: '0px 15px' }}>{endereco}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ padding: '0px 10px' }}>
                        <div className="col-md-6">
                            <div className='grupo__campo'>
                                <label style={{ width: '100%' }}>Início: </label><br />
                                <div className='grupo__campo__form'>
                                    <input type='month' defaultValue={this.state.dataInicio} onChange={(event) => {
                                        this.setState({ dataInicio: event.target.value })
                                    }} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className='grupo__campo'>
                                <label style={{ width: '100%' }}>Término: </label><br />
                                <div className='grupo__campo__form'>
                                    <input type='month' defaultValue={this.state.dataFim} onChange={(event) => {
                                        this.setState({ dataFim: event.target.value })
                                    }} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row" style={{ padding: '0px 10px' }}>
                        <div className="col-md-6">
                            <div className='grupo__campo'>
                                <label style={{ width: '100%' }}>Valor do aluguel: </label><br />
                                <div className='grupo__campo__form'>
                                    <input type='number' onChange={(event) => {
                                        this.setState({ valorAluguel: event.target.value })
                                    }} />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        })

        this.servicos()
        this.abrirModal()
    }

    anularContrato = (contrato) => {
        console.log('anularContrato, contrato:', contrato)

        this.setState({
            contrato_id: contrato.id
        })

        let id = contrato.id
        let endereco = `${contrato.endereco}, ${contrato.numero} ${contrato.complemento}, ${contrato.bairro} - ${contrato.cidade} / ${contrato.estado}`

        this.setState({
            modal_titulo: `Rescindir contrato`,
            modal_botoes: <>
                <button className='button btn__primary--outline' onClick={this.fecharModal}>Fechar</button>
                <button className='button btn__primary' onClick={this.apiContratoRescisao}>Rescindir</button>
            </>,
            modal_corpo: <div>
                <div style={{ marginTop: '1%' }}>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='grupo__campo'>
                                <label style={{ width: '100%' }}>ID do contrato:  </label><br />
                                <p style={{ padding: '0px 15px' }}>{id}</p>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div className='grupo__campo'>
                                <label style={{ width: '100%' }}>Endereço: </label><br />
                                <p style={{ padding: '0px 15px' }}>{endereco}</p>
                            </div>
                        </div>
                    </div>
                    <div className='row' style={{ padding: '0px 10px' }}>
                        <div className='col-md-6'>
                            <div className='grupo__campo'>
                                <label style={{ width: '100%' }}>Data: </label><br />
                                <div className='grupo__campo__form'>
                                    <input
                                        type="date"
                                        onChange={(event) => { this.setState({ dataRescisao: event.target.value }) }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        })

        this.servicos()
        this.abrirModal()
    }

    servicos = async () => {
        try {
            let servicos = await api_freedom.get(`/servico/all`, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })
            if (servicos.data.data.indexOf('TOKEN') === -1) {
                this.setState({ servicos: servicos.data.data })
            }
        }
        catch (error) {
            console.log(error.message)
        }
    }

    apiContrato = async () => {

        if (this.state.dataFim !== "" && this.state.dataInicio !== "", this.state.valorAluguel !== "") {
            let dia = this.state.dia_vencimento_aluguel < 10 ? "0" + this.state.dia_vencimento_aluguel : this.state.dia_vencimento_aluguel
            let dataUnida = this.state.dataFim + '-' + dia;
            var data = moment(dataUnida).format('YYYY-MM-DD');
            this.setState({
                dataFim: data
            })

            await api_freedom.post('/contrato/update/data', {
                data_fim: data,
                valor_aluguel: this.state.valorAluguel,
                id: this.state.contrato_id,
                imobiliaria_id: this.state.dados_login.imobiliaria_id,
            }, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })

            await this.alugueis(this.state.contrato_id)

            this.limparState();
            this.fecharModal();
            this.props.history.push('/freedom/parcelas/' + this.state.contrato_id)
        } else {
            this.setState({
                aviso: 'Preencha todos os campos',
                displayAviso: true
            })
        }

    }

    apiContratoRescisao = async () => {
        console.log('apiContratoRescisao, dataRescisao:', this.state.dataRescisao)
        if (this.state.dataRescisao !== '') {
            let hoje = moment().format('YYYY-MM-DD')
            let rescisaoData = this.state.dataRescisao
            let data = moment(rescisaoData).format('YYYY-MM-DD')

            if (data <= hoje) {
                // data de rescisao definida no passado ou no dia
                await api_freedom.post('/contrato/update/rescisao', {
                    data_rescisao: data,
                    data_hoje: hoje,
                    id: this.state.contrato_id,
                    imobiliaria_id: this.state.dados_login.imobiliaria_id,
                    verificar_data_rescisao: 0
                }, { headers: { 'authorization': localStorage.getItem('autenticado_freedom') } })

                this.limparState();
                this.fecharModal();
            } else {
                // data de rescisao definida para uma data futura
                await api_freedom.post('/contrato/update/rescisao', {
                    data_rescisao: data,
                    data_hoje: hoje,
                    id: this.state.contrato_id,
                    imobiliaria_id: this.state.dados_login.imobiliaria_id,
                    verificar_data_rescisao: 1
                }, { headers: { 'authorization': localStorage.getItem('autenticado_freedom') } })

                this.limparState();
                this.fecharModal();
            }
        }
    }

    apiContratoRescisaoVerificacao = async (record, dataHoje) => {
        let rescisaoData = moment(record.data_rescisao).format('YYYY-MM-DD')
        try {
            await api_freedom.post('/contrato/update/rescisao', {
                data_rescisao: rescisaoData,
                data_hoje: dataHoje,
                id: record.id,
                imobiliaria_id: this.state.dados_login.imobiliaria_id,
                verificar_data_rescisao: 2
            }, { headers: { 'authorization': localStorage.getItem('autenticado_freedom') } })
            return true
        } catch {
            return false
        }

    }

    limparState = () => {
        this.setState({
            dataFim: '',
            dataInicio: '',
            data_rescisao: '',
            valorAluguel: '',
            displayAviso: false
        })
    }

    alugueis = async (contrato_id) => {
        this.setState({
            modal_corpo: 'Carregando...'
        })

        var servico = this.state.servicos.filter((s) => {
            return s.nome === 'Aluguel'
        })[0].id
        var referencia


        var mes = moment(this.state.dataInicio).format('MM')
        var ano = moment(this.state.dataInicio).format('YYYY')

        var data_vencimento = moment(`${ano}-${mes}-${this.state.dia_vencimento_aluguel}`).format('YYYY-MM-DD')

        if (moment() > moment(data_vencimento)) {
            data_vencimento = moment(data_vencimento).add(1, 'months').format('YYYY-MM-DD')
        }

        while (moment(data_vencimento) <= moment(this.state.dataFim)) {
            if (moment(data_vencimento).format('DD') !== this.state.dia_vencimento_aluguel && moment(data_vencimento).format('MM') !== '02') {
                mes = moment(data_vencimento).format('MM')
                ano = moment(data_vencimento).format('YYYY')
                data_vencimento = moment(`${ano}-${mes}-${this.state.dia_vencimento_aluguel}`).format('YYYY-MM-DD')
            }
            if (this.state.aberto_fechado === 1) {
                referencia = `Aluguel referente ${moment(data_vencimento).subtract(1, 'months').startOf('month').format('DD/MM/YYYY')} a ${moment(data_vencimento).subtract(1, 'months').endOf('month').format('DD/MM/YYYY')}`
            }
            else {
                if (moment(data_vencimento).add(1, 'months').format('DD') !== this.state.dia_vencimento_aluguel && moment(data_vencimento).add(1, 'months').format('MM') === '03') {
                    var mes_correcao = moment(data_vencimento).add(1, 'months').format('MM')
                    var ano_correcao = moment(data_vencimento).add(1, 'months').format('YYYY')
                    referencia = `Aluguel referente ${moment(data_vencimento).format('DD/MM/YYYY')} a ${moment(`${ano_correcao}/${mes_correcao}/${this.state.dia_vencimento_aluguel}`).subtract(1, 'days').format('DD/MM/YYYY')}`
                }
                else {
                    referencia = `Aluguel referente ${moment(data_vencimento).format('DD/MM/YYYY')} a ${moment(data_vencimento).add(1, 'months').subtract(1, 'days').format('DD/MM/YYYY')}`
                }
            }

            await api_freedom.post('/financeiro/create', {
                contrato_id: this.state.contrato_id,
                imobiliaria_id: jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id,
                servico_id: servico,
                data_vencimento: data_vencimento,
                valor: this.state.valorAluguel,
                referencia: referencia,
                mes_ano: moment(data_vencimento).format('YYYY-MM')
            }, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })



            data_vencimento = moment(data_vencimento).add(1, 'months').format('YYYY-MM-DD')
        }

    }

    sendXLSX = async () => {
        let form = new FormData()

        form.append("pla", this.state.planilha)
        form.append("imob", this.state.dados_login.imobiliaria_id)

        let datas = jwt.sign({ data: this.state.planilhaData }, 'DATAS')

        let { data } = await api_freedom.post("/contrato/planilha", form, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })

        if (data == "error") {
            alert("Houve erro ao processar o arquivo, verifique a formatação do arquvo")
        }
        else {
            localStorage.setItem("dataleakitem", datas)
            window.location.href = '/freedom/importados/'
        }

        this.fecharModal()
    }

    fecharModalXLSX = () => {
        var modal = document.getElementById('modal_with_table');
        modal.style.display = "none";
    }

    readXLSX = async (file) => {
        let r = await readxlsx(file)

        let c = []

        for (const i of r.slice(1, r.length)) {
            c.push(i[0])
        }

        let contrato = await api_freedom.post("contrato/getbylist/", { contrato: c }, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })

        this.setState({
            planilhaData: r,
            planExistsContrato: contrato.data.data
        })
    }

    openModalXLSX = () => {
        var modal = document.getElementById('modal_with_table');
        modal.style.display = "flex";
    }

    getDataContratoDoc = async (contrato_id, imobiliaria_id) => {
        let re = await api_freedom(`/docs/docs/${contrato_id}/${imobiliaria_id}`, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })

        this.setState({
            docs_contrato: re.data.data
        })
    }

    openModalDocs = async (contrato) => {
        let re = await api_freedom(`/docs/docs/${contrato.id}/${contrato.imobiliaria_id}`, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })

        this.setState({
            docs_contrato: re.data.data
        })

        var modal = document.getElementById('modal__docs');
        modal.style.display = "flex";

        this.setState({
            contratoSelected: contrato,
            nome_doc: '',
            file_doc: null
        })
    }

    fecharModalDocs = () => {
        var modal = document.getElementById('modal__docs');

        modal.style.display = "none";
    }

    sendFile = async () => {
        try {
            this.setState({ load: true })
            let form = new FormData()

            if (!this.state.file_doc || !this.state.nome_doc) {
                return alert("Preencha todos os campos")
            }

            form.append('contrato_id', this.state.contratoSelected.id)
            form.append('imobiliaria_id', this.state.contratoSelected.imobiliaria_id)
            form.append('file', this.state.file_doc)
            form.append('nome', this.state.nome_doc)

            await api_freedom.post('/docs/newDoc', form, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })

            document.getElementById('file_doc').value = ''

            this.setState({
                nome_doc: '',
                send_docs: false
            })

            this.getDataContratoDoc(this.state.contratoSelected.id, this.state.contratoSelected.imobiliaria_id)
        } catch (error) {
            console.log(error.message)
            alert("Houve um erro ao enviar o documento");
        } finally {
            this.setState({ load: false })
        }
    }

    sendMailDocs = async () => {
        let emails = Array.from(document.getElementsByName("box__mails")).map(e => e.value).join(";")

        if (this.state.box_mail) {
            emails += `;${this.state.box_mail_other}`
        }

        emails = emails.replace(/ /g, '')

        await api_freedom.post("/docs/sendmail", {
            url: api_freedom_link + "/" + this.state.file_doc_selected.arquivo,
            data: moment(this.state.file_doc_selected.data).format('DD/MM/YYYY HH:MM'),
            email: emails,
            imob: this.state.contratoSelected.imobiliaria_id
        }, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })

        alert("Email enviado com sucesso")
    }

    deleteDoc = async (id) => {
        try {
            this.setState({ load: true })
            await api_freedom.post('/docs/docs/delete', {
                id: id
            }, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })

            alert("Documento removido com sucesso")
            this.getDataContratoDoc(this.state.contratoSelected.id, this.state.contratoSelected.imobiliaria_id)
        } catch (error) {
            alert("Houve um erro ao remover o documento")
            console.log(error.message)
        } finally {
            this.setState({ load: false })
        }
    }

    filteer = (ara) => {
        let filtro = ara.filter(v => {
            return this.filtrarIm(v) && this.filtrarContrato(v) && this.filtrarPt(v) && this.filtrarLc(v) && this.filtrarIn(v) && this.filtrarTm(v) && this.filtrarRc(v) && this.filtrarVa(v);
        })
        return filtro;
    }

    changeAtivo = (e) => {
        this.setState({
            ativos: e,
            contratosAtivados: this.filtroAtivo(this.state.contratos, e)
        });
    }

    fotprint = () => {
        this.setState({ inPrint: true })

        setTimeout(() => {
            window.print()

            this.setState({ inPrint: false })
        }, 350);

    }

    genCSV = () => {
        let csv = '"Contrato","Imóvel","Proprietário","Locatário","Início","Término","Valor do aluguel"\n';

        for (const i of this.filtrar(this.state.contratosAtivados)) {
            csv += `"${i.id}","${i.endereco}, ${i.numero} ${i.complemento}, ${i.bairro} - ${i.cidade} / ${i.estado}",`

            let locador = i.pessoas.filter((p) => p.tipo === 'Locador').map((l) => `${l.nome} | ${l.cpf_cnpj}`)
            let locatario = i.pessoas.filter((p) => p.tipo === 'Locatário').map((l) => `${l.nome} | ${l.cpf_cnpj}`)

            csv += `"${locador}","${locatario}","${moment(i.data_inicio).add(3, "hour").format('DD/MM/YYYY')}","${moment(i.data_fim).add(3, "hour").format('DD/MM/YYYY')}","${i.valor_aluguel.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}",\n`
        }

        csv = csv.slice(0, csv.length - 1)

        var hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
        hiddenElement.target = '_blank';
        hiddenElement.download = 'contratos.csv';
        hiddenElement.click();
    }

    toPdf = () => {
        var linkDemonstrativo = jwt.sign({
            pesquisa: {
                imobiliaria_id: this.state.dados_login.imobiliaria_id,
                pagina: this.state.pagina,
                limite: this.state.por_pagina,
                ativo: this.state.ativos,
                contrato_id: this.state.buscar_contrato,
                rua_imovel: this.state.fim,
                proprietario: this.state.fpt,
                locatario: this.state.flc,
                data_inicio: this.state.fin,
                data_rescisao: this.state.frc,
                data_termino: this.state.ftm,
                valor_aluguel: this.state.fva,
                pdf: true
            }
        }, 'CONTRATOS')
        window.open(`/freedom/relatoriocontratospdf/${linkDemonstrativo}`, '_blank');
    }

    render() {
        return (
            <>
                <div>
                    {/* {this.state.contratos.length > 0 &&  */}
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h2 style={{ display: "none" }} className="toshow">Relatório de contratos</h2>
                            <div>
                                <button class="button btn__primary tohidden" hidden={this.disable()} onClick={() => this.props.history.push('cadastrarcontrato')}>
                                    Adicionar Contrato
                                </button>
                                <button class="button btn__primary tohidden" hidden={this.disable()} onClick={() => this.openModalXLSX()}>
                                    Importar contratos
                                </button>
                                <button class="button btn__primary" hidden={this.disable()} onClick={this.toPdf}>
                                    Imprimir relatório
                                </button>
                                <button class="button btn__primary" hidden={this.disable()} onClick={this.genCSV}>
                                    Gerar CSV
                                </button>

                                {/* {this.disable() ? '' : <br/>} */}
                            </div>
                            <div>
                                <label style={{ margin: '10px 0px 0px 10px' }}>Filtro</label>
                                <select className="ml-2"
                                    value={this.state.ativos}
                                    onChange={(i) => {
                                        this.setState({ ativos: i.target.value, pagina: 1 })
                                    }}
                                >
                                    <option value="">Todos</option>
                                    <option value="1">Ativos</option>
                                    <option value="0">Inativos</option>
                                    <option value="3">Regulares</option>
                                    <option value="2">Vencidos</option>
                                </select>
                                <label style={{ margin: '10px 0px 0px 10px' }}>Por página aqui</label>
                                <select className="ml-2"
                                    value={this.state.por_pagina}
                                    onChange={(i) => {
                                        this.setState({ por_pagina: i.target.value, pagina: 1 })
                                    }}
                                >
                                    <option value="10">10</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <label style={{ margin: '10px 0px 0px 10px' }}>Total de contratos: {this.state.total_contratos}</label>
                            <div>
                                <label style={{ margin: '10px 0px 0px 10px' }}>Pagina: {this.state.quantidade_paginas > 0 ? this.state.pagina : 0} de {this.state.quantidade_paginas}</label>
                            </div>
                        </div>
                        {/* <div>
                        Contrato: <input type='text' value={this.state.buscar_contrato} onChange={(event) => {
                            this.setState({buscar_contrato: event.target.value, pagina: 1})
                        }}/>
                    </div>     */}
                    </div>
                    {/* } */}
                    <div className='table mt-4'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>
                                        <div class="thead__item">
                                            <label for="fct">Contrato</label>
                                            <div class="thead__item__input">
                                                <i class="fas fa-search"></i>
                                                <input id="fct" type='text' value={this.state.buscar_contrato} onChange={(event) => { this.setState({ buscar_contrato: event.target.value, pagina: 1 }) }} />
                                            </div>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label for="fim">Imóvel</label>
                                            <div class="thead__item__input">
                                                <i class="fas fa-search"></i>
                                                <input id="fim" type="text" value={this.state.fim} onChange={e => this.setState({ fim: e.target.value, pagina: 1 })} />
                                            </div>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label for="fpt">Proprietário(s)</label>
                                            <div class="thead__item__input">
                                                <i class="fas fa-search"></i>
                                                <input id="fpt" type="text" value={this.state.fpt} onChange={e => {
                                                    if (this.state.flc) {
                                                        this.setState({ fpt: '' })
                                                        alert("Locatário já preenchido. Se deseja procurar por Proprietário apague o campo Locatário.")
                                                    } else this.setState({ fpt: e.target.value, pagina: 1 })
                                                }
                                                } />
                                            </div>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label for="fcl">Locatário</label>
                                            <div class="thead__item__input">
                                                <i class="fas fa-search"></i>
                                                <input id="fcl" type="text" value={this.state.flc} onChange={e => {
                                                    if (this.state.fpt) {
                                                        this.setState({ flc: '' })
                                                        alert("Proprietário já preenchido. Se deseja procurar por Locatário apague o campo Proprietário.")
                                                    } else this.setState({ flc: e.target.value, pagina: 1 })
                                                }} />
                                            </div>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label for="fin">Início</label>
                                            <div class="thead__item__input">
                                                <i class="fas fa-search"></i>
                                                <input id="fin" type="date" value={this.state.fin} onChange={e => this.setState({ fin: e.target.value, pagina: 1 })} />
                                            </div>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label for="ftm">Término</label>
                                            <div class="thead__item__input">
                                                <i class="fas fa-search"></i>
                                                <input id="ftm" type="date" value={this.state.ftm} onChange={e => this.setState({ ftm: e.target.value, pagina: 1 })} />
                                            </div>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="thead__item">
                                            <label for="fva">Valor do aluguel</label>
                                            <div class="thead__item__input">
                                                <i class="fas fa-search"></i>
                                                <input id="fva" type="text" value={this.state.fva} onChange={e => this.setState({ fva: e.target.value, pagina: 1 })} />
                                            </div>
                                        </div>
                                    </th>
                                    <th className="tohidden">
                                        <div class="thead__item">
                                            {/* <label></label> */}
                                            {/* <div class="thead__item__input">
                                    </div> */}
                                            <button
                                                className="button btn__primary"
                                                style={{ marginTop: 0 }}
                                                onClick={() => this.contratos()}
                                            >
                                                <i style={{ fontSize: "0.9em" }} className="fas fa-search" />
                                                {" "}Pesquisar
                                            </button>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            {
                                this.state.inPrint == false ? (
                                    <tbody>
                                        {this.state.contratos.length === 0 && <tr><th className='text-center' colSpan='8'>{this.state.mensagem}</th></tr>}
                                        {this.state.contratos.map(this.listarContratos)}
                                        {/* {this.filtrar(this.state.contratosAtivados).slice((this.state.pagina - 1) * 10, this.state.pagina * 10).map(this.listarContratos)} */}
                                    </tbody>
                                ) : (
                                    <tbody>
                                        {this.state.contratos.length === 0 && <tr><th className='text-center' colSpan='8'>{this.state.mensagem}</th></tr>}
                                        {this.state.contratos.map(this.listarContratos)}
                                        {/* {this.filtrar(this.state.contratosAtivados).map(this.listarContratos)} */}
                                    </tbody>
                                )
                            }

                        </table>
                    </div>
                    {this.state.contratos.length > 0 && <div className='pagination'>
                        {this.state.pagina === 1 ? null : (
                            <div className='pagination__arrow' onClick={() => {
                                this.setState({ pagina: this.state.pagina > 1 ? this.state.pagina - 1 : 1 })
                            }}>
                                {'<'}
                            </div>
                        )}
                        {this.state.pagina === 1 || this.state.pagina === 2 ? null : (
                            <div className="pagination__arrow ml-4" onClick={() => {
                                this.setState({ pagina: 1 })
                            }}>
                                {'1'}
                            </div>
                        )}

                        <div className="pagination__numbers">
                            {
                                [...Array(this.state.quantidade_paginas)].map((pagina, index) => {
                                    if ((this.state.pagina === 1 && index + 1 < 4) ||
                                        (this.state.pagina === this.state.quantidade_paginas && index + 1 > this.state.quantidade_paginas - 3) ||
                                        (index + 1 === this.state.pagina - 1 || index + 1 === this.state.pagina || index + 1 === this.state.pagina + 1)
                                    ) {
                                        return (
                                            <p className={this.state.pagina === index + 1 ? 'pagination__index' : ''} onClick={() => {
                                                this.setState({ pagina: index + 1 })
                                            }}>
                                                {index + 1}
                                            </p>
                                        )
                                    }
                                    else {
                                        return null
                                    }
                                })
                            }
                        </div>
                        {this.state.pagina === this.state.quantidade_paginas || this.state.pagina === (this.state.quantidade_paginas - 1) ? null : (
                            <div className="pagination__arrow mr-4" onClick={() => {
                                this.setState({ pagina: this.state.quantidade_paginas })
                            }}>
                                {this.state.quantidade_paginas}
                            </div>
                        )}
                        {this.state.pagina === this.state.quantidade_paginas ? null : (
                            <div className="pagination__arrow" onClick={() => {
                                this.setState({ pagina: this.state.pagina < this.state.quantidade_paginas ? this.state.pagina + 1 : this.state.quantidade_paginas })
                            }}>
                                {'>'}
                            </div>
                        )}
                    </div>}

                    <div className="content-modal" id="modal" style={{ display: 'none' }}>
                        <div className="modal">
                            <div className="modal-head">
                                {this.state.modal_titulo}
                            </div>
                            <div className="modal-body">
                                <div className='modal-scroll'>
                                    {this.state.modal_corpo}
                                    <p className="erro" style={{ display: this.state.displayAviso ? 'block' : 'none' }}>{this.state.aviso}</p>
                                </div>
                            </div>
                            <div className="modal-foot">
                                {this.state.modal_botoes}
                            </div>
                        </div>
                    </div>

                    <div className="content-modal" id="modal__docs" style={{ display: 'none' }}>
                        <div className="modal">
                            <div className="modal-head">
                                Documentos
                            </div>
                            <div className="modal-body">
                                <div className='modal-scroll'>
                                    {
                                        this.state.send_docs == false ? (
                                            this.state.mail_docs ? (
                                                <>
                                                    <button className='tohidden btn__freedom btn--normal' onClick={a => this.setState({ mail_docs: false })}>
                                                        Lista de arquivos
                                                    </button>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        {
                                                            this.state.contratoSelected.pessoas.map(e => (
                                                                <div class="col-md-12 d-flex">
                                                                    <label class="mb-0 checkbox-label">
                                                                        <input type="checkbox" value={e.email} name="box__mails" id={e.id_relacao} />
                                                                        <span class="checkbox-custom rectangular"></span>
                                                                    </label>
                                                                    <label className="ml-3">{e.nome} ({e.tipo})</label>
                                                                </div>
                                                            ))
                                                        }
                                                        <div class="col-md-12 d-flex">
                                                            <label class="mb-0 checkbox-label">
                                                                <input type="checkbox" name="box__mails_other" onChange={a => this.setState({ box_mail: a.target.checked })} value={this.state.box_mail} />
                                                                <span class="checkbox-custom rectangular"></span>
                                                            </label>
                                                            <label className="ml-3">
                                                                Outro
                                                                {
                                                                    this.state.box_mail ? (
                                                                        <input value={this.state.box_mail_other} onChange={a => this.setState({ box_mail_other: a.target.value })} className="grupo__input" />
                                                                    ) : (null)
                                                                }
                                                            </label>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <button className='tohidden button btn__primary' onClick={a => this.setState({ send_docs: true })}>
                                                        Upload de arquivo
                                                    </button>
                                                    <br />
                                                    <br />

                                                    <table className="table">
                                                        <thead>
                                                            <th>Nome</th>
                                                            <th>Data</th>
                                                            <th>Opções</th>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.docs_contrato.map(e => (
                                                                    <tr>
                                                                        <td>{e.nome}</td>
                                                                        <td>{moment(e.data).format('DD/MM/YYYY HH:MM')}</td>
                                                                        <td>
                                                                            <select onChange={async a => {
                                                                                switch (a.target.value) {
                                                                                    case "1":
                                                                                        try {
                                                                                            this.setState({ load: true })
                                                                                            const response = await api_freedom.get(`/docs/download/${encodeURIComponent(e.arquivo)}`, {
                                                                                                responseType: 'blob',
                                                                                                headers: { "authorization": localStorage.getItem('autenticado_freedom') }
                                                                                            });
                                                                                            const url = window.URL.createObjectURL(new Blob([response.data]));
                                                                                            const link = document.createElement('a');
                                                                                            link.href = url;
                                                                                            link.setAttribute('download', e.arquivo);
                                                                                            document.body.appendChild(link);
                                                                                            link.click();
                                                                                        } catch (error) {
                                                                                            console.error('Erro ao fazer o download do arquivo:', error);
                                                                                            alert("Erro ao fazer o download do arquivo")
                                                                                        } finally {
                                                                                            this.setState({ load: false })
                                                                                        }
                                                                                        break;
                                                                                    case "2":
                                                                                        this.setState({
                                                                                            mail_docs: true,
                                                                                            file_doc_selected: e
                                                                                        })
                                                                                        break;

                                                                                    case "3":
                                                                                        if (window.confirm("Deseja realmente apagar o documento?")) {
                                                                                            this.deleteDoc(e.id)
                                                                                        }
                                                                                        break

                                                                                    default:
                                                                                        break;
                                                                                }
                                                                            }}>
                                                                                <option value="0">Opção</option>
                                                                                <option value="1">Baixar</option>
                                                                                {/* <option value="2">Enviar email</option> */}
                                                                                <option value="3">Apagar</option>
                                                                            </select>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                </>
                                            )
                                        ) : (
                                            <>
                                                <button className='tohidden button btn__primary' onClick={a => this.setState({ send_docs: false })}>
                                                    Lista de arquivos
                                                </button>
                                                <br />
                                                <br />

                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className='grupo__campo__form'>
                                                            <label style={{ width: '100%' }}>Nome: </label><br />
                                                            <input className="grupo__input" onChange={a => this.setState({ nome_doc: a.target.value })} value={this.state.nome_doc} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className='grupo__campo__form'>
                                                            <label style={{ width: '100%' }}>Arquivo: </label><br />
                                                            <input className="grupo__input" id="file_doc" type="file" onChange={a => this.setState({ file_doc: a.target.files[0] })} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="modal-foot">
                                <button className='button btn__primary--outline' onClick={this.fecharModalDocs}>Fechar</button>
                                {
                                    this.state.send_docs ? (
                                        <button className='button btn__primary' onClick={this.sendFile}>Enviar</button>
                                    ) : (null)
                                }
                                {
                                    this.state.mail_docs ? (
                                        <button className='button btn__primary' onClick={this.sendMailDocs}>Enviar</button>
                                    ) : (null)
                                }
                            </div>
                        </div>
                    </div>

                    <div className="content-modal" id="modal_with_table" style={{ display: 'none' }}>
                        <div className="highest-modal">
                            <div className="modal-head">
                                Inclusão de contrato por planilha
                            </div>
                            <div className="modal-body">
                                <div className='modal-scroll'>
                                    <div>
                                        <div style={{ marginTop: '1%' }}>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className='grupo__campo'>
                                                        <label style={{ width: '100%' }}>Arquivo: </label><br />
                                                        <input type="file" accept=".xlsx" onChange={a => {
                                                            this.setState({ planilha: a.target.files[0] });
                                                            this.readXLSX(a.target.files[0])

                                                        }} />
                                                    </div>
                                                </div>

                                                {
                                                    this.state.planilhaData.length > 0 ? (
                                                        <div className="col-md-12">
                                                            <table className="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>
                                                                            Contrato existente?
                                                                        </th>
                                                                        {
                                                                            this.state.planilhaData[0].map(e => (
                                                                                <th>{e}</th>
                                                                            ))
                                                                        }
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        this.state.planilhaData.slice(1, this.state.planilhaData.length).map(e => (
                                                                            <tr>
                                                                                <td>
                                                                                    {
                                                                                        this.state.planExistsContrato.find(y => y.id == e[0]) ? (
                                                                                            <label class="mb-0 checkbox-label">
                                                                                                <input type="checkbox" value={true} checked={true} readOnly />
                                                                                                <span class="checkbox-custom rectangular"></span>
                                                                                            </label>
                                                                                        ) : (
                                                                                            <label class="mb-0 checkbox-label">
                                                                                                <input type="checkbox" value={false} checked={false} readOnly />
                                                                                                <span class="checkbox-custom rectangular"></span>
                                                                                            </label>
                                                                                        )
                                                                                    }
                                                                                </td>
                                                                                {
                                                                                    e.map(j => (
                                                                                        <td>
                                                                                            {
                                                                                                (j instanceof Date) ? j.toLocaleDateString() : j == null || j == undefined ? '---' : j.toString()
                                                                                            }
                                                                                        </td>
                                                                                    ))
                                                                                }
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    ) : (null)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <p className="erro" style={{ display: this.state.displayAviso ? 'block' : 'none' }}>{this.state.aviso}</p>
                                </div>
                            </div>
                            <div className="modal-foot">
                                <button className='button btn__primary--outline' onClick={this.fecharModalXLSX}>Fechar</button>
                                <button className='button btn__primary' onClick={this.sendXLSX}>Enviar</button>
                            </div>
                        </div>
                    </div>

                    <div className="content-modal" style={{ display: this.state.load ? 'flex' : 'none' }}>
                        <div className="modal" style={{ width: "auto" }}>
                            <img src={require("../assets/imgs/loading.gif")} alt="" style={{ height: "200px" }} />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Contratos;


import React from "react"
import { api_freedom } from '../services/apis'
import jwt_decode from 'jsonwebtoken'
import moment from 'moment'

class DemonstrativoLocador extends React.Component {

	constructor(props) {
		super(props)

		try {
			this.state = {
				contrato: {},
				descricao_parcela: [],
				pessoa: {},
				mensagem: 'Carregando ....',
				valor_ted_locador: 0,
				valor_repasse: 0,
				informacoes: jwt_decode.decode(props.match.params.identificador),
				ted: {}
			}
		}
		catch (erro) {
			this.state = {
				contrato: {},
				descricao_parcela: [],
				pessoa: {},
				mensagem: 'Carregando ....',
				valor_ted_locador: 0,
				valor_repasse: 0,
				ted: {}
			}
			console.log(erro.message)
		}
	}

	componentDidMount() {
		this.contrato()
		// console.log(jwt_decode.decode(this.props.match.params.identificador))
		this.cobrancas()
	}

	contrato = async () => {
		try {
			let contrato = await api_freedom.get(`/demonstrativo/contrato/detalhe/${this.state.informacoes.contrato_id}/${this.state.informacoes.imobiliaria_id ? this.state.informacoes.imobiliaria_id : 53}/${this.state.informacoes.pessoa_id}`, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })
			// console.log(contrato.data.data[0]);
			if (contrato.data.data.indexOf('TOKEN') === -1) {
				this.state.contrato = contrato.data.data[0]

				this.setState({
					contrato: contrato.data.data[0]
				})
			}
		}
		catch (error) {
			console.log(error.message)
		}
	}

	cobrancas = async () => {
		try {
			let parcelas = await api_freedom.get(`/demonstrativo/financeiro/idcontrato/${this.state.informacoes.contrato_id}/${this.state.informacoes.imobiliaria_id ? this.state.informacoes.imobiliaria_id : 53}`, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })
			if (parcelas.data.data.indexOf('TOKEN') === -1) {
				let parcela = parcelas.data.data.filter((p) => {
					return p.id === parseInt(this.state.informacoes.financeiro_id)
				})[0]

				let descricao_parcela = parcelas.data.data.filter((p) => {
					return p.mes_ano === parcela.mes_ano
				})

				console.log(this.state.informacoes.pessoa_id);

				let transf = descricao_parcela.find(e => e.servico_id == 2 && e.pessoa_id == this.state.informacoes.pessoa_id)
				console.log('descricao')
				console.log(descricao_parcela);
				if (transf.ted_id) {
					let ted_locador = descricao_parcela.find(e => e.id == transf.ted_id)
					if (ted_locador == undefined)
						return;
					this.setState({
						valor_ted_locador: ted_locador.valor
					})
				}

				let valor_repasse = transf.valor

				this.setState({
					descricao_parcela: descricao_parcela,
					mensagem: 'Nenhum registro encontrado.',
					valor_repasse: valor_repasse,
					ted: transf
				})

				this.pessoa()
			}
		}
		catch (error) {
			console.log(error.message)
		}
	}

	pessoa = async () => {
		try {
			let pessoa = await api_freedom.post(`/pessoa/cpfcnpj`, {
				cpf_cnpj: this.state.ted.cpf_cnpj,
				imobiliaria_id: this.state.ted.imobiliaria_id
			}, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })

			if (pessoa.data.data.indexOf('TOKEN') === -1) {

				if (pessoa.data.data.length > 0) {
					this.setState({
						pessoa: pessoa.data.data[0],
						mensagem: 'Nenhum registro encontrado.'
					})
				}
			}
		} catch (error) {
			console.log(error.message)
		}

	}

	listarDescricaoParcela = (record, index) => {
		if (record.nome.toLowerCase() == "tarifa de ted") {
			return null
		}
		//if para calculo do valor de IR, onde se nao tiver o id da pessoa, ele nao será mostrado.
		//quando é desconto de IR, nao é preciso fazer o calculo de acordo com a porcentagem.
		if (record.servico_id == 6 && record.pessoa_id == this.state.informacoes.pessoa_id) {
			return (
				<tr key={index} className="stripedColor">
					<td align="left" style={{ fontSize: '14px', padding: '5px' }}>{record.referencia ? record.referencia : record.nome}</td>
					<td align="right" style={{ fontSize: '14px' }}>{parseInt(record.descontar_repasse) === 1 ? '-' : ''}{record.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
				</tr>
			)
		} else if (record.servico_id == 6 && record.pessoa_id != this.state.informacoes.pessoa_id) {
			return null;
		}

		const percentagem = this.state.contrato.pessoas ? this.state.contrato.pessoas.filter((p) => {
			return p.tipo === 'Locador' && this.state.informacoes.pessoa_id === p.id
		}) : []

		const valor_repasse = percentagem.length > 0 ? parseFloat(record.valor) * (parseFloat(percentagem[0].percentagem) / 100) : parseFloat(record.valor)

		return (
			<tr key={index} className="stripedColor">
				<td align="left" style={{ fontSize: '14px', padding: '5px' }}>{record.referencia ? record.referencia : record.nome}</td>
				<td align="right" style={{ fontSize: '14px' }}>{parseInt(record.descontar_repasse) === 1 ? '-' : ''}{valor_repasse.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
			</tr>
		)
	}

	render() {
		return (
			<>
				<div className='demonstrativo'>
					<div className="container" style={{ color: '#000000' }}>
						<div style={{
							width: '100%',
							height: '100%',
							margin: '0',
							boxSizing: 'border-box',
							fontFamily: 'sans-serif',
							backgroundColor: '#f9f9f9',
							fontSize: '18px'
						}}>
							<div style={{
								width: '100%',
								padding: '0px 15px',
								paddingTop: '30px',
								marginLeft: 'auto',
								marginRight: 'auto',
								backgroundColor: '#fff',
								borderBottom: '1px solid #eee'
							}}>
								<p style={{ fontSize: '12px' }}><span style={{ fontWeight: 'bold' }}>Contrato</span> {this.state.informacoes.contrato_id}</p>
								<h4 style={{ margin: '0' }}>
									Extrato de &nbsp;
									{this.state.ted.pessoa_nome}
								</h4>
								{/* <p style={{fontSize: '12px'}}>Posição em 23/09/2020</p> */}

								<div className='table-responsive'>
									<table className='table' style={{ width: '100%' }}>
										<tr>
											<th align="left" style={{ fontSize: '14px', padding: '5px' }} colSpan="1">Contrato</th>
											<td style={{ fontSize: '14px' }} colSpan="5">{this.state.informacoes.contrato_id} - {this.state.contrato.imovel ? `${this.state.contrato.imovel[0].endereco}, ${this.state.contrato.imovel[0].numero} ${this.state.contrato.imovel[0].complemento}, ${this.state.contrato.imovel[0].bairro} - ${this.state.contrato.imovel[0].cidade} / ${this.state.contrato.imovel[0].estado} - ${this.state.contrato.imovel[0].cep}` : ''}</td>
										</tr>
										<tr style={{ backgroundColor: '#eee' }}>
											<th align="left" style={{ fontSize: '14px', padding: '5px' }}>Início</th>
											<td style={{ fontSize: '14px' }}>{this.state.contrato.data_inicio ? moment(this.state.contrato.data_inicio).utc(false).format('DD/MM/YYYY') : ``}</td>
											<th align="left" style={{ fontSize: '14px', padding: '5px' }}>Término</th>
											<td style={{ fontSize: '14px' }}>{this.state.contrato.data_fim ? moment(this.state.contrato.data_fim).utc(false).format('DD/MM/YYYY') : ``}</td>
											<th align="left" style={{ fontSize: '14px', padding: '5px' }}></th>
											<td style={{ fontSize: '14px' }}></td>
										</tr>
										<tr>
											<th align="left" style={{ fontSize: '14px', padding: '5px' }}>Locador</th>
											<td style={{ fontSize: '14px' }}>{this.state.pessoa.nome}</td>
											<th align="left" style={{ fontSize: '14px', padding: '5px' }}>CPF</th>
											<td style={{ fontSize: '14px' }}>{this.state.pessoa.cpf_cnpj}</td>
											<th align="left" style={{ fontSize: '14px', padding: '5px' }}>Email</th>
											<td style={{ fontSize: '14px' }}>{this.state.pessoa.email?.toLowerCase()}</td>
										</tr>
									</table>
								</div>

								<div style={{ margin: '40px 0px' }}>
									<table style={{ width: '100%' }}>
										<tr>
											<td style={{ margin: '0px', fontSize: '14px', color: '#999', padding: '5px', borderBottom: '1px solid #333' }} colSpan="2">Cobrança</td>
										</tr>
										<tr>
											<th align="left" style={{ fontSize: '14px', padding: '5px', width: '100px' }}>Referência</th>
											<td style={{ fontSize: '14px' }}>{this.state.descricao_parcela.length > 0 && moment(this.state.descricao_parcela.filter((p) => {
												return p.id === parseInt(this.state.informacoes.financeiro_id)
											})[0].data_vencimento).utc(false).format('DD/MM/YYYY')}</td>
										</tr>
										<tr>
											<th align="left" style={{ fontSize: '14px', padding: '5px', width: '100px' }}>Data da TED</th>
											<td style={{ fontSize: '14px' }}>
												{
													this.state.ted.payment_date ? (
														moment(this.state.ted.payment_date).utc(false).format('DD/MM/YYYY')
													) : ("---")
												}</td>
										</tr>
										<tr>
											<th align="left" style={{ fontSize: '14px', padding: '5px', width: '100px' }}>Valor da TED</th>
											<td style={{ fontSize: '14px' }}>
												{this.state.valor_repasse.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
											</td>
										</tr>
										<tr>
											<th align="left" style={{ fontSize: '14px', padding: '5px', width: '100px' }}>Beneficiário da TED</th>
											<td style={{ fontSize: '14px' }}>
												{this.state.ted.name_destino}
											</td>
										</tr>
									</table>
								</div>

								<div className='table-responsive' style={{ margin: '40px 0px' }}>
									<table className='table' style={{ width: '100%' }}>
										<tr>
											<td style={{ margin: '0px', fontSize: '14px', color: '#999', padding: '5px', borderBottom: '1px solid #333' }}>Descrição do repasse</td>
											<td style={{ margin: '0px', fontSize: '14px', color: '#999', padding: '5px', textAlign: 'right', borderBottom: '1px solid #333' }}>Valor</td>
										</tr>
										{this.state.descricao_parcela.filter((dp) => {
											return dp.somar_repasse === 1 || dp.descontar_repasse === 1
										}).map(this.listarDescricaoParcela)}

										{
											(this.state.contrato.cobrar_ted == 1 || this.state.contrato.cobrar_ted == null) && (this.state.valor_ted_locador !== 0) ? (
												<tr className="stripedColor">
													<td align="left" style={{ fontSize: '14px', padding: '5px' }}>Tarifa de TED</td>
													<td align="right" style={{ fontSize: '14px' }}>
														{`-${parseFloat(this.state.valor_ted_locador).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`}
													</td>
												</tr>
											) : (null)
										}

										<tr style={{ fontWeight: 'bolder' }}>
											<td align="left" style={{ fontSize: '14px', padding: '5px' }}>Total do repasse</td>
											<td align="right" style={{ fontSize: '14px' }}>{(parseFloat(this.state.valor_repasse)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
										</tr>
									</table>
								</div>
							</div>

						</div>
					</div>
				</div>
			</>
		);
	}
}

export default DemonstrativoLocador;
